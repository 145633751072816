<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        تعديل الصلاحيات
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/admins')"
        />
      </template>
    </Toolbar>
    <div class="form-card text-right roles">
      <div class="mb-3">
        <h3>المرقبه</h3>
        <div class="p-field-checkbox">
          <Checkbox
            id="logsVeiw"
            name="roles"
            value="logsVeiw"
            v-model="body.roles"
          />
          <label for="logsVeiw">عرض</label>
        </div>
      </div>

      <div class="mb-3">
        <h3>المديرين</h3>
        <div class="p-field-checkbox">
          <Checkbox
            id="adminsVeiw"
            name="roles"
            value="adminsVeiw"
            v-model="body.roles"
          />
          <label for="adminsVeiw">عرض</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="adminsAdd"
            name="roles"
            value="adminsAdd"
            v-model="body.roles"
          />
          <label for="adminsAdd">اضافه</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="adminsEdit"
            name="roles"
            value="adminsEdit"
            v-model="body.roles"
          />
          <label for="adminsEdit">تعديل</label>
        </div>

        <div class="p-field-checkbox">
          <Checkbox
            id="adminsDelete"
            name="roles"
            value="adminsDelete"
            v-model="body.roles"
          />
          <label for="adminsDelete">حذف</label>
        </div>
      </div>

      <div class="mb-3">
        <h3>المسابقات</h3>
        <div class="p-field-checkbox">
          <Checkbox
            id="leagueVeiw"
            name="roles"
            value="leagueVeiw"
            v-model="body.roles"
          />
          <label for="leagueVeiw">عرض</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="leagueAdd"
            name="roles"
            value="leagueAdd"
            v-model="body.roles"
          />
          <label for="leagueAdd">اضافه</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="leagueEdit"
            name="roles"
            value="leagueEdit"
            v-model="body.roles"
          />
          <label for="leagueEdit">تعديل</label>
        </div>

        <div class="p-field-checkbox">
          <Checkbox
            id="leagueDelete"
            name="roles"
            value="leagueDelete"
            v-model="body.roles"
          />
          <label for="leagueDelete">حذف</label>
        </div>
      </div>

      <div class="mb-3">
        <h3>المواليد المشاركه</h3>
        <div class="p-field-checkbox">
          <Checkbox
            id="yearsVeiw"
            name="roles"
            value="yearsVeiw"
            v-model="body.roles"
          />
          <label for="yearsVeiw">عرض</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="yearsAdd"
            name="roles"
            value="yearsAdd"
            v-model="body.roles"
          />
          <label for="yearsAdd">اضافه</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="yearsEdit"
            name="roles"
            value="yearsEdit"
            v-model="body.roles"
          />
          <label for="yearsEdit">تعديل</label>
        </div>

        <div class="p-field-checkbox">
          <Checkbox
            id="yearsDelete"
            name="roles"
            value="yearsDelete"
            v-model="body.roles"
          />
          <label for="yearsDelete">حذف</label>
        </div>
      </div>

      <div class="mb-3">
        <h3>الاكاديميات</h3>
        <div class="p-field-checkbox">
          <Checkbox
            id="academiesVeiw"
            name="roles"
            value="academiesVeiw"
            v-model="body.roles"
          />
          <label for="academiesVeiw">عرض</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="academiesAdd"
            name="roles"
            value="academiesAdd"
            v-model="body.roles"
          />
          <label for="academiesAdd">اضافه</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="academiesEdit"
            name="roles"
            value="academiesEdit"
            v-model="body.roles"
          />
          <label for="academiesEdit">تعديل</label>
        </div>

        <div class="p-field-checkbox">
          <Checkbox
            id="academiesDelete"
            name="roles"
            value="academiesDelete"
            v-model="body.roles"
          />
          <label for="academiesDelete">حذف</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="leagueYears"
            name="roles"
            value="leagueYears"
            v-model="body.roles"
          />
          <label for="leagueYears"> التحكم في المواليد والفرق</label>
        </div>
      </div>

      <div class="mb-3">
        <h3>المدربين</h3>
        <div class="p-field-checkbox">
          <Checkbox
            id="coachVeiw"
            name="roles"
            value="coachVeiw"
            v-model="body.roles"
          />
          <label for="coachVeiw">عرض</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="coachDelete"
            name="roles"
            value="coachDelete"
            v-model="body.roles"
          />
          <label for="coachDelete">حذف</label>
        </div>
      </div>

      <div class="mb-3">
        <h3>الادريين</h3>
        <div class="p-field-checkbox">
          <Checkbox
            id="mindfulVeiw"
            name="roles"
            value="mindfulVeiw"
            v-model="body.roles"
          />
          <label for="mindfulVeiw">عرض</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="mindfulDelete"
            name="roles"
            value="mindfulDelete"
            v-model="body.roles"
          />
          <label for="mindfulDelete">حذف</label>
        </div>
      </div>

      <div class="mb-3">
        <h3>المديرين الفنين</h3>
        <div class="p-field-checkbox">
          <Checkbox
            id="technicalManagerVeiw"
            name="roles"
            value="technicalManagerVeiw"
            v-model="body.roles"
          />
          <label for="technicalManagerVeiw">عرض</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="technicalManagerDelete"
            name="roles"
            value="technicalManagerDelete"
            v-model="body.roles"
          />
          <label for="technicalManagerDelete">حذف</label>
        </div>
      </div>

      <div class="mb-3">
        <h3>اللاعبين</h3>
        <div class="p-field-checkbox">
          <Checkbox
            id="playersVeiw"
            name="roles"
            value="playersVeiw"
            v-model="body.roles"
          />
          <label for="playersVeiw">عرض</label>
        </div>

        <div class="p-field-checkbox">
          <Checkbox
            id="playersDelete"
            name="roles"
            value="playersDelete"
            v-model="body.roles"
          />
          <label for="playersDelete">حذف</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="movePlayersVeiw"
            name="roles"
            value="movePlayersVeiw"
            v-model="body.roles"
          />
          <label for="movePlayersVeiw">نقل</label>
        </div>
      </div>

      <div class="mb-3">
        <h3>الكرينهات</h3>
        <div class="p-field-checkbox">
          <Checkbox
            id="carnicsVeiw"
            name="roles"
            value="carnicsVeiw"
            v-model="body.roles"
          />
          <label for="carnicsVeiw">عرض</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="carnicsAdd"
            name="roles"
            value="carnicsAdd"
            v-model="body.roles"
          />
          <label for="carnicsAdd">اضافه</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="carnicsEdit"
            name="roles"
            value="carnicsEdit"
            v-model="body.roles"
          />
          <label for="carnicsEdit">تعديل</label>
        </div>

        <div class="p-field-checkbox">
          <Checkbox
            id="carnicsDelete"
            name="roles"
            value="carnicsDelete"
            v-model="body.roles"
          />
          <label for="carnicsDelete">حذف</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="carnicsPrint"
            name="roles"
            value="carnicsPrint"
            v-model="body.roles"
          />
          <label for="carnicsPrint"> طباعه الكرينهات</label>
        </div>
      </div>

      <div class="mb-3">
        <h3>اسكورشيت</h3>
        <div class="p-field-checkbox">
          <Checkbox
            id="ascorchitVeiw"
            name="roles"
            value="ascorchitVeiw"
            v-model="body.roles"
          />
          <label for="ascorchitVeiw">عرض</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="ascorchitAdd"
            name="roles"
            value="ascorchitAdd"
            v-model="body.roles"
          />
          <label for="ascorchitAdd">اضافه</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="ascorchitEdit"
            name="roles"
            value="ascorchitEdit"
            v-model="body.roles"
          />
          <label for="ascorchitEdit">تعديل</label>
        </div>

        <div class="p-field-checkbox">
          <Checkbox
            id="ascorchitDelete"
            name="roles"
            value="ascorchitDelete"
            v-model="body.roles"
          />
          <label for="ascorchitDelete">حذف</label>
        </div>
      </div>

      <div class="mb-3">
        <h3>المنظمين</h3>
        <div class="p-field-checkbox">
          <Checkbox
            id="organizersVeiw"
            name="roles"
            value="organizersVeiw"
            v-model="body.roles"
          />
          <label for="organizersVeiw">عرض</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="organizersAdd"
            name="roles"
            value="organizersAdd"
            v-model="body.roles"
          />
          <label for="organizersAdd">اضافه</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="organizersEdit"
            name="roles"
            value="organizersEdit"
            v-model="body.roles"
          />
          <label for="organizersEdit">تعديل</label>
        </div>

        <div class="p-field-checkbox">
          <Checkbox
            id="organizersDelete"
            name="roles"
            value="organizersDelete"
            v-model="body.roles"
          />
          <label for="organizersDelete">حذف</label>
        </div>
      </div>

      <div class="mb-3">
        <h3>الاخبار</h3>
        <div class="p-field-checkbox">
          <Checkbox
            id="newsVeiw"
            name="roles"
            value="newsVeiw"
            v-model="body.roles"
          />
          <label for="newsVeiw">عرض</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="newsAdd"
            name="roles"
            value="newsAdd"
            v-model="body.roles"
          />
          <label for="newsAdd">اضافه</label>
        </div>
        <div class="p-field-checkbox">
          <Checkbox
            id="newsEdit"
            name="roles"
            value="newsEdit"
            v-model="body.roles"
          />
          <label for="newsEdit">تعديل</label>
        </div>

        <div class="p-field-checkbox">
          <Checkbox
            id="newsDelete"
            name="roles"
            value="newsDelete"
            v-model="body.roles"
          />
          <label for="newsDelete">حذف</label>
        </div>
      </div>

      <div class="mb-3">
        <h3>استرجاع البيانات</h3>
        <div class="p-field-checkbox">
          <Checkbox
            id="softVeiw"
            name="roles"
            value="softVeiw"
            v-model="body.roles"
          />
          <label for="softVeiw">عرض</label>
        </div>
      </div>

      <div class="mb-3 text-center">
        <Button
          label="تعديل"
          icon="pi pi-pencil"
          class="p-ml-2 p-button-info"
          v-if="id != 1"
          @click="update()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        roles: [],
      },
      id: null,
    };
  },
  methods: {
    update() {
      this.$http
        .put(`users/${this.id}`, {
          roles: JSON.stringify(this.body.roles),
        })
        .then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.$router.push('/admin/admins');
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`users/${this.id}`).then(
        (res) => {
          this.body.roles = JSON.parse(res.data.roles);
        },
        (err) => {
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    }
  },
};
</script>

<style>
.roles label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
